export const questionsFaq = [
  {
    title: "What is qlip?",
    content:
      "Qlip is a video edition automation platform powered by AI through Natural Language Processing and Computer Vision models. No training data or machine learning knowledge are required. <br><br>Our API provides developers with automated video edition capabilities: generating clips & teasers, transcribing speech to text, burning subtitles, reframing horizontal videos to vertical or square aspects ratios while being focusses on the point of attention, etc.",
  },
  {
    title: "How does it work?",
    content:
      "After <a style='color:#beff34;' target='_blank' href='https://fdeo6yfrmko.typeform.com/to/J6gb4JEU?typeform-source=www.qlip.ai'>requesting access</a>, you will be given access to our API documentation with a token. At this point, you will be able to make you first API call: submit a video/audio file and get back the most viral clips in that file. <br><br>Our proprietary AI models have been trained on 100,000s hours of video and audio to identify the most impactful/intense/viral clips and teasers hidden in your content.",
  },
  {
    title: "What can I expect from qlip’s API?",
    content:
      "Our API empowers you to:<ul><li><p>Extract the most impactful &amp; meaningful segments of the content you uploaded in the form of:</p><ul><li><p>Text snippets with timestamps</p></li><li><p>Audio/video clips</p></li></ul></li><li><p>Change the aspect ratios of video clips from horizontal to vertical or square while being focussed on the point of attention</p></li><li><p>Burn subtitles on your video clips with custom templates &amp; animations</p></li></ul>",
  },
  {
    title: "With what type of content does it work best?",
    content: "Qlip's API specializes in processing conversation-driven content, leveraging advanced algorithms to analyze, summarize, and enhance spoken word videos. By focusing on content rich in dialogue and narratives, our API ensures high-quality outputs that are particularly beneficial for insightful and entertaining contents.<br><br>Our API shines when applied to audio or video podcasts, talk shows, TV shows or movies, commentaries and documentaries. It can extract key topics and even create clips that encapsulate the essence of conversations, improving accessibility and discoverability.<br><br>It thrives in promoting motivational speeches, product reviews or educational videos as it distills complex information in impacting capsules.<br><br>Not suitable for:<p><strong style='font-weight:600'>Gaming Videos</strong>: Content primarily focused on gameplay without sufficient dialogue may not provide the context needed for effective analysis and clip generation.</p><p ><strong>Music Videos</strong>: Videos where music is the main component, with minimal or no spoken words, are not ideal for our processing algorithms.</p>",
  },
  {
    title: "How to make sure it works best?",
    content:
      "To ensure optimal processing and output quality, videos should be rich in spoken words, such as those found in the content types mentioned above. Conversation-driven videos provide the necessary context and detail for our API to perform effectively.",
  },
  {
    title: "What languages are supported?",
    content:
      "We support English, Spanish, French, German and Dutch. And we are constantly expanding our language coverage.",
  },
  {
    title: "What do our customers love about us?",
    content:
      "The benefits that our customers enjoy the most are: improved content discoverability, better content engagement metrics, and the ability to seamlessly integrate clipping feature into their workflows without having to deal with the underlying complexity.",
  },
  {
    title: "How can I try it?",
    content:
      "<a style='color:#beff34;' target='_blank' href='https://fdeo6yfrmko.typeform.com/to/J6gb4JEU?typeform-source=www.qlip.ai'>Get in touch</a>, tell us more about your needs, we’ll size it together and give you access to our studio playground with a GUI to test all features.",
  }
]
