import { PropsWithChildren, useEffect, useRef } from "react";
// @mui/material
import {
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Container } from "@material-ui/core";
// @utils
import { redirect } from "../utils/navigate";

interface HeaderProps {
  onApiPage: boolean;
}

export default function Header({ onApiPage }: HeaderProps): JSX.Element {
  const theme = useTheme();
  const demo =
    "https://qlip-public-media.s3.eu-west-3.amazonaws.com/qlip_demo_short_lowres.mp4";
  const responsiveMobile = useMediaQuery(theme.breakpoints.down("xl"));
  const responsiveMobileMd = useMediaQuery(theme.breakpoints.down("md"));
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    // Play the video when the component is mounted or refreshed
    if (videoRef.current) {
      videoRef.current.volume = 0.1;
      videoRef.current.play().catch((error) => {
        // Autoplay might be blocked by the browser, handle the error here
        console.error("Autoplay failed:", error);
      });
    }
  }, []);

  return (
    <Container maxWidth="lg">
      <Box style={{ textAlign: "center", marginTop: "50px" }}>
        <Box pt="2rem" pb="3rem">
          <Typography variant="h1" color={theme.palette.primary.main} fontSize={responsiveMobileMd ? '3rem' : '4rem'} lineHeight={1} width='100%' maxWidth={responsiveMobileMd ? '85%' : '70%'} mx="auto">
            <HighlightedSpan>Adapt</HighlightedSpan> your video library to social media <HighlightedSpan>automatically</HighlightedSpan>
          </Typography>

          <Button
            color="inherit"
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.16)",
              background: theme.palette.secondary.main,
              boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
              backdropFilter: "blur(10px)",
              marginTop: "3rem",
              width: "175px",
              height: "52px",
              borderRadius: "40px",
              textTransform: "none",
              "&:hover": {
                background: theme.palette.secondary.main,
              },
            }}
            onClick={() =>
              redirect(
                onApiPage
                  ? "https://fdeo6yfrmko.typeform.com/to/J6gb4JEU"
                  : "https://app.qlip.ai/signup"
              )
            }
          >
            <Typography variant="h6">
              {onApiPage ? "Get access" : "Start for free"}
            </Typography>
          </Button>
        </Box>

        <video
          preload="metadata"
          ref={videoRef}
          playsInline
          autoPlay
          muted
          loop
          style={{
            borderRadius: "24px",
            width: "100%",
            marginTop: "20px",
            marginLeft: responsiveMobile ? "0px" : "-10px",
            marginBottom: "30px",
          }}
        >
          <source src={demo} type="video/mp4" />
        </video>
      </Box>
    </Container>
  );
}

function HighlightedSpan({ children }: PropsWithChildren<{}>) {
  const theme = useTheme();
  return <span
    style={{
      fontFamily: "PolySans-MedianItalic",
      color: theme.palette.secondary.main,
    }}
  >{children}</span>
}