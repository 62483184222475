// @mui/material
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// @data
import { benefits } from "../data/apiBenefits";
import { redirect } from "../utils/navigate";
import { useState } from "react";

const buttonTypes: Array<{ type: 'web' | 'api', text: string }> = [
  {type: 'web', text: 'Web app'},
  {type: 'api', text: 'API'},
]

export default function ApiBenefits(): JSX.Element {
  const theme = useTheme();
  const responsiveMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const [benefitType, setBenefitType] = useState<'api' | 'web'>('web');

  return (
    <Box
      minHeight="600px"
      sx={{
        background: `url("./images/bg-start-qlipping.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center" sx={{
        maxWidth: "1280px",
        marginInline: "auto",
        paddingInline: "24px",
      }}>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          mb={10}
          mt={5}
          sx={{ paddingX: responsiveMobile ? "30px" : "0px" }}
        >
          Manage your AI generated clips
        </Typography>

        <Stack flexDirection="row" gap="1rem" paddingBottom="3rem">
          {buttonTypes.map(({ type, text }) => (
            <Button className={benefitType === type ? 'active' : ''} onClick={() => setBenefitType(type)} sx={{
              padding: '0.2rem 1rem',
              backgroundColor: '#85a0df',
              borderRadius: '40px',
              minWidth: "150px",
              height: "40px",
              position: "relative",
              boxShadow: "2px 2px 6px -2px #000000b5",
              "&:hover": {
              backgroundColor: '#85a0df',
              },
              "> span.active": {
                position: 'absolute',
                inset: '3px',
                borderRadius: '37px',
                opacity: 0.75,
              },
              "> .MuiTypography-h6": {
                zIndex: 1,
                fontSize: '0.9rem'
              },
              "&.active": {
                boxShadow: "none",
                cursor: 'default'
              },
              "&.active > span.active": {
                backgroundImage: 'linear-gradient(90deg, #9584ff, #7AA2FF, #BEFF34)',
              }
            }} disableRipple><span className="active"></span><Typography variant="h6">{text}</Typography></Button>
          ))}
          {/* <Button className={benefitType === 'api' ? 'active' : ''} onClick={() => setBenefitType('api')} sx={{
            padding: '0.2rem 1rem',
            backgroundColor: '#85a0df',
            borderRadius: '40px',
            minWidth: "120px",
            position: "relative",
            boxShadow: "2px 2px 6px -2px #000000b5",
            "&:hover": {
            backgroundColor: '#85a0df',
            },
            "> span.active": {
              position: 'absolute',
              inset: '3px',
              borderRadius: '37px',
              opacity: 0.75,
            },
            "> .MuiTypography-h6": {
              zIndex: 1,
            },
            "&.active": {
              boxShadow: "none",
              cursor: 'default'
            },
            "&.active > span.active": {
              backgroundImage: 'linear-gradient(90deg, #9584ff, #7AA2FF, #BEFF34)',
            }
          }} disableRipple><span className="active"></span><Typography variant="h6">API</Typography></Button> */}
        </Stack>

        <Grid
          container
          display="flex"
          spacing={3}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingX: responsiveMobile ? "15px" : "0px",
          }}
        >
          {benefits[benefitType].map((benefit) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              key={benefit.title}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "rgba(128, 128, 128, 0.2)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  width: "500px",
                  height: "100px",
                  padding: "24px 32px",
                  borderRadius: "24px",
                  border: "1px solid rgba(255, 255, 255, 0.40)",
                  position: "relative",
                }}
              >
                <CardMedia
                  component="img"
                  image={benefit.icon}
                  alt={`icon ${benefit.icon}`}
                  sx={{
                    width: "40px",
                    height: "40px",
                    marginRight: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />

                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "0.3rem"
                  }}
                >
                  <Typography variant="h4" align="center">
                    {benefit.title}
                  </Typography>

                  <Typography variant="h5" align="center">
                    {benefit.description}
                  </Typography>
                </CardContent>

                <CardMedia
                  component="img"
                  image={benefit.additionalImage}
                  alt={`Image ${benefit.additionalImage}`}
                  sx={{
                    width: "108px",
                    height: "102px",
                    position: "absolute",
                    top: 15,
                    right: 0,
                  }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>

        <Button
          color="inherit"
          sx={{
            background: theme.palette.secondary.main,
            border: "1px solid rgba(0, 0, 0, 0.16)",
            boxShadow: "0px 8px 12px -4px rgba(0, 0, 0, 0.16)",
            backdropFilter: "blur(10px)",
            marginTop: "3rem",
            marginBottom: "3rem",
            width: responsiveMobile ? "175px" : "300px",
            height: responsiveMobile ? "52px" : "70px",
            borderRadius: "40px",
            textTransform: "none",
            "&:hover": {
              background: theme.palette.secondary.main,
            },
          }}
          onClick={() => redirect("https://fdeo6yfrmko.typeform.com/to/J6gb4JEU")}
        >
          <Typography variant="h6">Get access</Typography>
        </Button>
      </Box>
    </Box>
  );
}
